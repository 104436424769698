import React from "react";

const Start = (props) => (
  <div className="component">
    <header className="app-header">
      <h2>جمعية الاتحاد الإسلامي</h2>
      <h3>مشروع الأضحى السنوي</h3>
      {props.active && (
        <h3>
          {props.year}م - {props.hijri}هـ
        </h3>
      )}
    </header>
    <div>
      {props.active ? (
        <img className="splash" src="img/sheep.png" alt="خروف" />
      ) : (
        <img className="splash" src="img/sleep.png" alt="خروف" />
      )}
    </div>
  </div>
);

export default Start;

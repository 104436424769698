import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MobileStepper from "@material-ui/core/MobileStepper";
import Radio from "@material-ui/core/Radio";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React, { Component } from "react";
import WhatParts from "./WhatParts";
import WhatParts2 from "./WhatParts2";
import WhatPartsCow from "./WhatPartsCow";

class WantParts extends Component {
  state = {
    steps: 0,
    step: 0,
    orders: {},
    whatParts: {}, // this is a temporary variable just to make it easier to
    // reference the current order
  };

  constructor(props) {
    super(props);
    this.state.steps = props.orders.length;
    this.state.orders = props.orders;
    this.state.step = 0;
    this.state.whatParts = props.orders[0];
  }

  gotoStep(step) {
    this.setState(
      {
        step: step,
        whatParts: this.state.orders[step],
      },
      () => {
        this.forceUpdate();
      }
    );
  }

  handleNext = () => {
    this.gotoStep(this.state.step + 1);
  };

  handlePrevious = () => {
    this.gotoStep(this.state.step - 1);
  };

  handleWantPartsChange = (event) => {
    let o = this.state.orders;
    o[this.state.step].wantParts = parseInt(event.target.value);
    this.setState({ orders: o });
    this.props.handleOrdersChange(o);
  };

  handleWhatPartsChange = (value) => {
    let o = this.state.orders;
    o[this.state.step] = value;
    this.setState({ orders: o });
    this.props.handleOrdersChange(o);
  };

  render() {
    return (
      <div className="component">
        <h3>هل تريد حصص من الأضحية:</h3>
        <MobileStepper
          variant="dots"
          steps={this.state.steps}
          position="static"
          activeStep={this.state.step}
          className="wantparts-stepper"
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={this.state.step === this.state.steps - 1}
            >
              الأضحية التالية
              <KeyboardArrowLeft />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handlePrevious}
              disabled={this.state.step === 0}
            >
              <KeyboardArrowRight />
              الأضحية السابقة
            </Button>
          }
        />
        <table className="wantpart-header">
          <tbody>
            <tr>
              <th>رقـــــم</th>
              <td id="wantpart-h1">{this.state.step + 1}</td>
              <th>الـنـــوع</th>
              <td id="wantpart-h2">{this.state.whatParts.a.label}</td>
            </tr>
          </tbody>
        </table>
        {this.state.whatParts.a.category < 3 ? (
          <div>
            <table className="wantpart-wantparts">
              <tbody>
                <tr>
                  <td>
                    <FormControlLabel
                      value="0"
                      label="لا أريد حصص."
                      control={
                        <Radio
                          checked={this.state.whatParts.wantParts === 0}
                          onChange={this.handleWantPartsChange}
                          value="0"
                        />
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      value="1"
                      label="أريد بعض الحصص."
                      control={
                        <Radio
                          checked={this.state.whatParts.wantParts === 1}
                          onChange={this.handleWantPartsChange}
                          value="1"
                        />
                      }
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      value="2"
                      label="أريد كل الحصص."
                      control={
                        <Radio
                          checked={this.state.whatParts.wantParts === 2}
                          onChange={this.handleWantPartsChange}
                          value="2"
                        />
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {this.state.whatParts.wantParts === 1 &&
              this.state.whatParts.a.category === 1 &&
              (Number(this.props.partsMethod) === 0 ? (
                <WhatParts
                  whatParts={this.state.whatParts}
                  handleWhatPartsChange={this.handleWhatPartsChange}
                />
              ) : (
                <WhatParts2
                  whatParts={this.state.whatParts}
                  handleWhatPartsChange={this.handleWhatPartsChange}
                />
              ))}
            {this.state.whatParts.wantParts === 1 &&
              this.state.whatParts.a.category === 2 && (
                <WhatPartsCow
                  whatParts={this.state.whatParts}
                  cowType={1}
                  handleWhatPartsChange={this.handleWhatPartsChange}
                />
              )}
          </div>
        ) : (
          <div>لا يمكنك الحصول على حصص من هذا النوع.</div>
        )}
      </div>
    );
  }
}

export default WantParts;

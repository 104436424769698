import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Home from '@material-ui/icons/Home';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Person from '@material-ui/icons/Person';
import Phone from '@material-ui/icons/Phone';
import React, { Component } from 'react';

class Delivery extends Component {
  state = {
    deliveryName: "",
    deliveryPhone: "",
    deliveryHome: false,
    deliveryStreet: "",
    deliverySector: ""
  }

  constructor(props) {
    super(props);
    this.state.deliveryName = props.deliveryName;
    this.state.deliveryPhone = props.deliveryPhone;
    this.state.deliveryHome = props.deliveryHome;
    this.state.deliveryStreet = props.deliveryStreet;
    this.state.deliverySector = props.deliverySector;
  }

  handleChange = name => event => {
    var v = event.target.value;
    if (name === "deliveryHome")
      v = event.target.checked ? 1 : 0;
    console.log(name, v);
    this.setState({
      [name]: v,
    }, () => {
      this.props.handleDeliveryChange(
        this.state.deliveryName,
        this.state.deliveryPhone,
        this.state.deliveryHome,
        this.state.deliveryStreet,
        this.state.deliverySector);
    });
  };

  render() {
    return (
      <div className="component">
        <h3>إسم المستلم للأضحية</h3>
        <TextField
          id="delivery_name"
          label="اسم مستلم الأضحية"
          value={this.state.deliveryName}
          onChange={this.handleChange('deliveryName')}
          margin="normal"
          className="input-text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="delivery_phone"
          type="tel"
          label="رقم هاتف مستلم اﻷضحية"
          value={this.state.deliveryPhone}
          onChange={this.handleChange('deliveryPhone')}
          margin="normal"
          className="input-text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            ),
          }}
        />
        {(this.props.enableHomeDelivery || this.props.forceHomeDelivery) && <div>
          <FormControlLabel value="1" label="هل تريد توصيل الأضحية إلى المنزل؟" control={
            <Checkbox name="deliveryHome" value="1"
              checked={this.state.deliveryHome === 1 || this.props.forceHomeDelivery}
              onChange={this.handleChange('deliveryHome')}
            />}
          />
          {(this.state.deliveryHome === 1 || this.props.forceHomeDelivery) &&
            <div>
              <TextField
                id="delivery_street"
                label="العنوان"
                helperText="الرجاء تحديد الشارع، البناية، الطابق، بالقرب من أو مقابل مكان معروف لو وجد"
                value={this.state.deliveryStreet}
                onChange={this.handleChange('deliveryStreet')}
                margin="normal"
                className="input-text"
                multiline
                rowsMax="3"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalShipping />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="delivery_sector"
                label="المنطقة"
                value={this.state.deliverySector}
                onChange={this.handleChange('deliverySector')}
                margin="normal"
                className="input-text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Home />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          }
        </div>}
      </div>
    );
  }
}

export default Delivery;


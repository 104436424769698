import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

class WhatPartsCow extends Component {
  state = {
    whatParts: {
      partsLegs: 0,
      partsBack: 0,
      partsShoulders: 0,
      partsNeck: 0,
      partsGuts: 0,
      partsCow: 0,
      otherCheck: 0,
      partsOther: ''
    }
  }

  constructor(props) {
    super(props);
    this.state.whatParts = props.whatParts;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ whatParts: nextProps.whatParts });
  }

  handleCountChange = event => {
    let value = Number(event.target.value);
    if (value < 0) value = 0;
    if (this.props.cowType === 1 && value > 9) {
      value = value % 10;
    }
    if (this.props.cowType === 2 && value > 63) value = 63;
    this.changeValue('partsCow', value);
  };

  handleCheckChange = event => {
    let value = Number(event.target.value);
    if (value === this.state.whatParts.otherCheck)
      value = 0;
    this.changeValue('otherCheck', value);
  };

  changeValue(name, value) {
    let whatParts = this.state.whatParts;
    whatParts[name] = value;
    if (name === "otherCheck" && value === 0) {
      whatParts.partsOther = "";
    }
    this.setState({ whatParts: whatParts });
    this.props.handleWhatPartsChange(whatParts);
  }

  handleOtherChange = event => {
    let whatParts = this.state.whatParts;
    whatParts.partsOther = event.target.value;
    this.setState({ whatParts: whatParts });
    this.props.handleWhatPartsChange(whatParts);
  };

  handleFocus = event => {
    event.target.select();
  }

  render() {
    return (<div>
      <table className="what-parts">
        <tbody>
          <tr>
            <td>
              <TextField
                className="input-text"
                id="outlined-number"
                label="الحصص المطلوبة"
                value={this.state.whatParts.partsCow}
                onChange={this.handleCountChange}
                type="number"
                margin="normal"
                onFocus={this.handleFocus}
              />
            </td>
            <td>
              <FormControlLabel value="1" label="غير ذلك" control={
                <Checkbox name="otherCheck" value="1"
                  checked={this.state.whatParts.otherCheck === 1}
                  onChange={this.handleCheckChange}
                />}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              {this.state.whatParts.otherCheck === 1 &&
                <TextField
                  className="input-text"
                  name="partsOther"
                  label="الرجاء تحديد"
                  multiline
                  rowsMax="3"
                  value={this.state.whatParts.partsOther}
                  onChange={this.handleOtherChange}
                  margin="dense"
                />}
            </td>
          </tr>
        </tbody>
      </table>
    </div >);
  }
}

export default WhatPartsCow;

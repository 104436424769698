import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";

//TODO: check if the user's requested parts than did not select anything

class WhatParts2 extends Component {
  state = {
    whatParts: {
      partsLegs: 0,
      partsBack: 0,
      partsShoulders: 0,
      partsNeck: 0,
      partsGuts: 0,
      partsCow: 0,
      otherCheck: 0,
      partsOther: "",
    },
  };

  constructor(props) {
    super(props);
    this.state.whatParts = props.whatParts;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ whatParts: nextProps.whatParts });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = Number(event.target.value);
    let whatParts = this.state.whatParts;
    if (value === whatParts[name]) value = 0;
    whatParts[name] = value;
    if (name === "otherCheck" && value === 0) {
      whatParts.partsOther = "";
    }
    this.setState({ whatParts: whatParts });
    this.props.handleWhatPartsChange(whatParts);
  };

  handleOtherChange = (event) => {
    let whatParts = this.state.whatParts;
    whatParts.partsOther = event.target.value;
    this.setState({ whatParts: whatParts });
    this.props.handleWhatPartsChange(whatParts);
  };

  render() {
    return (
      <div>
        <h3>{this.state.whatParts.no}</h3>
        <table className="what-parts">
          <tbody>
            <tr>
              <td>
                <FormControlLabel
                  value="1"
                  label="فخذ واحد"
                  control={
                    <Checkbox
                      name="partsLegs"
                      value="1"
                      checked={this.state.whatParts.partsLegs === 1}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
              <td>
                <FormControlLabel
                  value="2"
                  label="فخذين"
                  control={
                    <Checkbox
                      name="partsLegs"
                      value="2"
                      checked={this.state.whatParts.partsLegs === 2}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
              <td>
                <FormControlLabel
                  value="1"
                  label="رقبة"
                  control={
                    <Checkbox
                      name="partsNeck"
                      value="1"
                      checked={this.state.whatParts.partsNeck === 1}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormControlLabel
                  value="1"
                  label="كتف واحد"
                  control={
                    <Checkbox
                      name="partsShoulders"
                      value="1"
                      checked={this.state.whatParts.partsShoulders === 1}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
              <td>
                <FormControlLabel
                  value="2"
                  label="كتفين"
                  control={
                    <Checkbox
                      name="partsShoulders"
                      value="2"
                      checked={this.state.whatParts.partsShoulders === 2}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
              <td>
                <FormControlLabel
                  value="1"
                  label="معلاق"
                  control={
                    <Checkbox
                      name="partsGuts"
                      value="1"
                      checked={this.state.whatParts.partsGuts === 1}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <FormControlLabel
                  value="1"
                  label="صدر واحد"
                  control={
                    <Checkbox
                      name="partsBack"
                      value="1"
                      checked={this.state.whatParts.partsBack === 1}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
              <td>
                <FormControlLabel
                  value="2"
                  label="صدرين"
                  control={
                    <Checkbox
                      name="partsBack"
                      value="2"
                      checked={this.state.whatParts.partsBack === 2}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
              <td>
                <FormControlLabel
                  value="1"
                  label="غير ذلك"
                  control={
                    <Checkbox
                      name="otherCheck"
                      value="1"
                      checked={this.state.whatParts.otherCheck === 1}
                      onChange={this.handleChange}
                    />
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                {this.state.whatParts.otherCheck === 1 && (
                  <TextField
                    className="input-text"
                    name="partsOther"
                    label="الرجاء تحديد"
                    multiline
                    rowsMax="3"
                    value={this.state.whatParts.partsOther}
                    onChange={this.handleOtherChange}
                    margin="dense"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default WhatParts2;

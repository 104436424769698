import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Email from "@material-ui/icons/Email";
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/Phone";
import React, { Component } from "react";

class Name extends Component {
  state = {
    name: "",
  };

  constructor(props) {
    super(props);
    this.state.name = this.props.name;
    this.state.email = this.props.email;
    this.state.mobile = this.props.mobile;
    this.state.referrer = this.props.referrer;
    // this.state.name = "Name Name";
    // this.state.email = "email@email.com";
    // this.state.mobile = "0123456789";
    // this.state.referrer = "Referrer Name";
  }

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        this.props.handleNameChange(
          this.state.name,
          this.state.email,
          this.state.mobile,
          this.state.referrer
        );
      }
    );
  };

  render() {
    return (
      <div className="component">
        <h3>إسم المضحّي</h3>
        <TextField
          required
          id="name"
          label="إسم المضحّي"
          value={this.state.name}
          onChange={this.handleChange("name")}
          margin="normal"
          className="input-text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          id="email"
          type="email"
          label="البريد اﻹلكتروني"
          value={this.state.email}
          onChange={this.handleChange("email")}
          margin="normal"
          className="input-text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          id="mobile"
          type="tel"
          label="رقم الخليوي"
          value={this.state.mobile}
          onChange={this.handleChange("mobile")}
          margin="normal"
          className="input-text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          id="referrer"
          label="إسم المعرّف"
          value={this.state.referrer}
          onChange={this.handleChange("referrer")}
          margin="normal"
          className="input-text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

export default Name;

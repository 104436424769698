import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";

class PaymentMethod extends Component {
  state = {
    paymentMethod: "0",
    notes: "",
  };

  constructor(props) {
    super(props);
    this.state.paymentMethod = props.paymentMethod.toString();
  }

  handlePaymentMethodChange = (event) => {
    let value = event.target.value;
    this.setState({ paymentMethod: value.toString() });
    this.props.handlePaymentMethodChange(value, this.state.notes);
  };

  handleNotesChange = (event) => {
    let value = event.target.value;
    console.log(value);
    this.setState({ notes: value });
    this.props.handlePaymentMethodChange(this.state.paymentMethod, value);
  };

  render() {
    let whatsApp =
      "https://wa.me/" + this.props.transferConfig.settings.transfer_phone;
    return (
      <div className="component">
        <h3>طريقة الدفع</h3>
        <RadioGroup
          name="niah"
          value={this.state.paymentMethod}
          onChange={this.handlePaymentMethodChange}
        >
          <FormControlLabel
            value="0"
            label="باليد في أحد مراكز الجمعية أو دور القرآن"
            control={<Radio />}
          />
          <FormControlLabel
            value="1"
            label="بواسطة المعرّف"
            control={<Radio />}
          />
          <FormControlLabel
            value="2"
            label="أنتظر مندوب الجمعيّة أن يحصّل المبلغ منّي"
            control={<Radio />}
          />
          <FormControlLabel
            value="3"
            label="عن طريق حوالة OMT/Western Union."
            control={<Radio />}
          />
        </RadioGroup>
        {this.state.paymentMethod === "3" && (
          <div className="payment-method-transfer-note">
            {this.props.transferConfig.settings.transfer_note}
            <a href={whatsApp}>
              {this.props.transferConfig.settings.transfer_phone}
            </a>
          </div>
        )}
        <TextField
          id="notes"
          className="input-text"
          label="ملاحظات"
          multiline
          rowsMax="5"
          value={this.state.notes}
          onChange={this.handleNotesChange}
          margin="normal"
        />
      </div>
    );
  }
}

export default PaymentMethod;

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { calcTotal } from './common';

class Review extends Component {
  paymentMethods = [
    "باليد في أحد مراكز الجمعية أو دور القرآن",
    "بواسطة المعرّف",
    "أنتظر مندوب الجمعيّة أن يحصّل المبلغ منّي",
    "عن طريق حوالة OMT/Western Union."
  ];
  state = {
    totalAmount: 0,
    confimDialog: false,
  }
  tempIndex = 0;

  handleReviewEvent(page) {
    this.props.handleReviewEvent(page);
  };

  handleDialogOpen = () => {
    this.setState({ confimDialog: true });
  };

  handleDialogOK = () => {
    this.setState({ confimDialog: false });
    this.props.handleSendOrder();
  };

  handleDialogCancel = () => {
    this.setState({ confimDialog: false });
  };

  render() {
    var totalAmount = calcTotal(this.props.orders, this.props.paymentMethod, this.props.transferCost);
    return (
      <div className="component">
        <h3 className="review-header">مـــــراجــعــة الـطــلــب</h3>
        {this.props.sendError && <div className="error">لقد حصل خطء في إراسل الطلبية!!!!<br /> يمكنك محاولة ارسالها من جديد.</div>}
        <table className="review">
          <tbody>
            <tr>
              <th>إسم المضحّي</th>
              <td colSpan="2">{this.props.name}</td>
            </tr>
            <tr>
              <th>البريد اﻹلكتروني</th>
              <td colSpan="2">{this.props.email}</td>
            </tr>
            <tr>
              <th>رقم الخليوي</th>
              <td colSpan="2">{this.props.mobile}</td>
            </tr>
            <tr>
              <th>إسم المعرّف</th>
              <td>{this.props.referrer}</td>
              <td className="rb">
                <Button variant="contained" size="small" color="primary"
                  onClick={() => this.handleReviewEvent(1)}> تصحيح</Button>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="review">
          <tbody>
            <tr>
              <th>نيّة الذبح</th>
              <td>{this.props.niah}</td>
              <td className="rb">
                <Button variant="contained" size="small" color="primary"
                  onClick={() => this.handleReviewEvent(2)}>تصحيح</Button>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="review-orders">
          <tbody>
            {this.props.orders.map((o, i) =>
              <tr key={i}>
                <th className="no">{i + 1}</th>
                <th className="type">{o.a.label}</th>
                <td>{o.partsStr}</td>
              </tr>
            )}
            <tr>
              <th></th>
              <th className="type">المبلغ المطلوب</th>
              <td className="rb">
                <div className="review-total">{totalAmount}</div>
                <Button variant="contained" size="small" color="primary"
                  onClick={() => this.handleReviewEvent(3)}>تصحيح</Button>
              </td>
            </tr>
          </tbody>
        </table>
        {!this.props.isTakaful &&
          <table className="review">
            {this.props.deliveryHome === 0 && !this.props.forceHomeDelivery ?
              <tbody>
                <tr>
                  <th>إسم المستلم</th>
                  <td colSpan="2">{this.props.deliveryName}</td>
                </tr>
                <tr>
                  <th>رقم المستلم</th>
                  <td>{this.props.deliveryPhone}</td>
                  <td className="rb">
                    <Button variant="contained" size="small" color="primary"
                      onClick={() => this.handleReviewEvent(5)}>تصحيح</Button>
                  </td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr>
                  <th>إسم المستلم</th>
                  <td colSpan="2">{this.props.deliveryName}</td>
                </tr>
                <tr>
                  <th>رقم المستلم</th>
                  <td colSpan="2">{this.props.deliveryPhone}</td>
                </tr>
                <tr>
                  <th>العنوان</th>
                  <td colSpan="2">{this.props.deliveryStreet}</td>
                </tr>
                <tr>
                  <th>المنطقة</th>
                  <td>{this.props.deliverySector}</td>
                  <td className="rb">
                    <Button variant="contained" size="small" color="primary"
                      onClick={() => this.handleReviewEvent(5)}>تصحيح</Button>
                  </td>
                </tr>
              </tbody>
            }
          </table>
        }
        <table className="review">
          <tbody>
            <tr>
              <th>طريقة الدفع</th>
              <td>{this.paymentMethods[this.props.paymentMethod]}</td>
            </tr>
            {this.props.notes ? <tr><th>ملاحظات</th><td>{this.props.notes}</td></tr> : null}
            <tr>
              <td colSpan="2" className="rb">
                <Button variant="contained" size="small" color="primary"
                  onClick={() => this.handleReviewEvent(6)}>تصحيح</Button>
              </td>
            </tr>
          </tbody>
        </table>
        <Button variant="contained" color="primary" className="fullwidth" onClick={this.handleDialogOpen}>
          إرسال الطلبية
        </Button>
        <Dialog
          open={this.state.confimDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"هل أنت متأكد؟"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              هل أنت متأكد من أنك تريد إرسال الطلبية?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogOK} color="primary">
              نـــعـــــم
            </Button>
            <Button onClick={this.handleDialogCancel} color="secondary" autoFocus>
              كـــــــــلا
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}



export default Review;

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import MobileStepper from "@material-ui/core/MobileStepper";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import axios from "axios";
import React, { Component } from "react";
import "./App.css";
import Delivery from "./Delivery";
import g, { getPrefix } from "./Globals";
import Name from "./Name";
import Niah from "./Niah";
import Orders from "./Orders";
import PaymentMethod from "./PaymentMethod";
import Region from "./Region";
import Review from "./Review";
import Start from "./Start";
import WantParts from "./WantParts";
import { calcTotal, whatPartsToString } from "./common";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

class App extends Component {
  state = {
    config: {
      online_active: false,
    },
    step: 0,
    regions: [],
    activeRegion: {},
    region: "b",
    name: "",
    email: "",
    mobile: "",
    referrer: "",
    deliveryName: "",
    deliveryPhone: "",
    deliveryHome: 0,
    deliveryStreet: "",
    deliverySector: "",
    niah: "أضحية",
    orders: [],
    paymentMethod: 0,
    notes: "",
    reviewMode: false,
    disalbeNext: false,
    sendError: false,
    enableHomeDelivery: false,
    forceHomeDelivery: false,
    isTakaful: true,
  };

  componentDidMount() {
    var self = this;
    let url = g.urlApi + "config";
    console.log(url);
    axios.get(url).then((res) => {
      var dat = res.data;
      var code = dat.regions[0].code;
      self.setState(
        {
          config: dat.config,
          regions: dat.regions,
          region: code,
          activeRegion: dat.regions[0],
        },
        () => {
          this.handleRegionChange(code);
        }
      );
    });
  }

  handleNext = () => {
    var step = this.state.step + 1;
    if (step === 6 && (!this.canHaveParts() || this.state.isTakaful)) step = 7;
    this.setState({
      reviewMode: false,
      step: step,
    });
  };

  handleReviewNext = () => {
    if (this.state.step === 5) {
      this.setState({
        step: 6,
      });
    } else {
      this.setState({
        reviewMode: false,
        step: 8,
      });
    }
  };

  handlePrevious = () => {
    var step = this.state.step - 1;
    if (step === 6 && (!this.canHaveParts() || this.state.isTakaful)) step = 5;
    this.setState({
      reviewMode: false,
      step: step,
    });
  };

  handleReviewEvent = (page) => {
    page++;
    this.setState({
      reviewMode: true,
      step: page,
    });
  };

  handleRegionChange = (code) => {
    var enableHomeDelivery = false;
    var deliveryHome = this.state.deliveryHome;
    var activeRegion = {};
    for (var i = 0; i < this.state.regions.length; i++) {
      if (this.state.regions[i].code === code) {
        enableHomeDelivery = this.state.regions[i].allow_home_delivery;
        activeRegion = this.state.regions[i];
        break;
      }
    }
    if (!enableHomeDelivery) deliveryHome = 0;
    this.setState({
      region: code,
      deliveryHome,
      enableHomeDelivery,
      activeRegion,
      orders: [],
    });
  };

  handleNameChange = (name, email, mobile, referrer) => {
    this.setState({
      name: name,
      email: email,
      mobile: mobile,
      referrer: referrer,
      disableNext:
        name === "" || email === "" || mobile === "" || referrer === "",
    });
  };

  handleDeliveryChange = (
    deliveryName,
    deliveryPhone,
    deliveryHome,
    deliveryStreet,
    deliverySector
  ) => {
    this.setState({
      deliveryName: deliveryName,
      deliveryPhone: deliveryPhone,
      deliveryHome: deliveryHome,
      deliveryStreet: deliveryStreet,
      deliverySector: deliverySector,
      disableNext:
        deliveryHome && (deliveryStreet === "" || deliverySector === ""),
    });
  };

  handleNiahChange = (value) => {
    this.setState({ niah: value });
  };

  updateWhatPartsStr(orders) {
    var disableNext = orders.length === 0;
    let forceHomeDelivery = false;
    let isTakaful = true;
    orders.forEach((o) => {
      if (o.a.category === 2 && o.wantParts > 0) {
        forceHomeDelivery = true;
      }
      o.partsStr = whatPartsToString(
        o,
        Number(this.state.activeRegion.settings.parts_method)
      );
      if (o.wantParts === 1 && o.partsStr === "") {
        disableNext = true;
      }
      if (o.wantParts > 0) {
        isTakaful = false;
      }
    });
    this.setState({
      orders,
      disableNext,
      forceHomeDelivery,
      isTakaful,
    });
  }

  handleOrdersChange = (value) => {
    this.updateWhatPartsStr(value);
  };

  handlePaymentMethodChange = (paymentMethod, notes) => {
    this.setState({
      paymentMethod: paymentMethod,
      notes: notes,
    });
  };

  checkIfWantsPartsandNotSpecified(orders) {
    var disableNext = orders.length === 0;
    if (!disableNext) {
      orders.forEach((o) => {
        if (o.wantParts === 1 && o.partsStr === "") {
          disableNext = true;
        }
      });
    }
    return disableNext;
  }

  checkDisableNext() {
    let result;
    switch (this.state.step) {
      case 2:
        result =
          this.state.name === "" ||
          this.state.email === "" ||
          this.state.mobile === "" ||
          this.state.referrer === "";
        break;
      case 4:
        result = this.state.orders.length === 0;
        break;
      case 5:
        result = this.checkIfWantsPartsandNotSpecified(this.state.orders);
        break;
      case 6:
        result =
          (this.state.deliveryHome === 1 || this.state.forceHomeDelivery) &&
          (this.state.deliveryStreet === "" ||
            this.state.deliverySector === "");
        break;
      case 8:
        result = true;
        break;
      default:
        result = false;
    }
    return result;
  }

  canHaveParts() {
    var result = false;
    for (var i = 0; i < this.state.orders.length; i++) {
      if (this.state.orders[i].a.category < 3) {
        result = true;
        break;
      }
    }
    return result;
  }

  handleSendOrder = () => {
    //var self = this;
    var info = {
      region: this.state.region,
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      referrer: this.state.referrer,
      deliveryName: this.state.deliveryName,
      deliveryPhone: this.state.deliveryPhone,
      deliveryHome: this.state.deliveryHome,
      deliveryStreet: this.state.deliveryStreet,
      deliverySector: this.state.deliverySector,
      niah: this.state.niah,
      orders: this.state.orders,
      paymentMethod: this.state.paymentMethod,
      notes: this.state.notes,
    };
    console.log(info);
    var prefix =
      "online/add?pre=" + getPrefix(this.state.region, this.state.config.year);
    axios
      .post(g.urlApi + prefix, info)
      .then((res) => {
        var ordersNo = res.data;
        if (ordersNo == null || ordersNo[0] === 0) {
          this.setState({
            step: 8,
            sendError: true,
          });
        } else {
          this.setState({
            step: 10,
            ordersNo: ordersNo,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          step: 8,
          sendError: true,
        });
      });
    this.setState({
      step: 9,
      sendError: false,
    });
  };

  render() {
    var totalAmount = 0;
    if (this.state.step === 10) {
      totalAmount = calcTotal(
        this.state.orders,
        this.state.paymentMethod,
        this.state.activeRegion.settings.transfer_cost
      );
    }
    return (
      <MuiThemeProvider theme={theme}>
        <div className="app" dir="rtl">
          <CssBaseline />
          {this.state.step === 0 && (
            <Start
              year={this.state.config.year}
              hijri={this.state.config.hijri}
              active={this.state.config.online_active}
            />
          )}
          {this.state.step === 1 && (
            <Region
              region={this.state.region}
              regions={this.state.regions}
              handleRegionChange={this.handleRegionChange}
            />
          )}
          {this.state.step === 2 && (
            <Name
              name={this.state.name}
              email={this.state.email}
              mobile={this.state.mobile}
              referrer={this.state.referrer}
              handleNameChange={this.handleNameChange}
            />
          )}
          {this.state.step === 3 && (
            <Niah
              niah={this.state.niah}
              nawayah={this.state.activeRegion.niah}
              handleNiahChange={this.handleNiahChange}
            />
          )}
          {this.state.step === 4 && (
            <Orders
              region={this.state.region}
              adahiTypes={this.state.activeRegion.adahi_type}
              orders={this.state.orders}
              handleOrdersChange={this.handleOrdersChange}
            />
          )}
          {this.state.step === 5 && (
            <WantParts
              orders={this.state.orders}
              partsMethod={this.state.activeRegion.settings.parts_method}
              handleOrdersChange={this.handleOrdersChange}
            />
          )}

          {this.state.step === 6 && (
            <Delivery
              deliveryName={this.state.deliveryName}
              deliveryPhone={this.state.deliveryPhone}
              deliveryHome={this.state.deliveryHome}
              deliveryStreet={this.state.deliveryStreet}
              deliverySector={this.state.deliverySector}
              enableHomeDelivery={this.state.enableHomeDelivery}
              forceHomeDelivery={this.state.forceHomeDelivery}
              handleDeliveryChange={this.handleDeliveryChange}
            />
          )}

          {this.state.step === 7 && (
            <PaymentMethod
              paymentMethod={this.state.paymentMethod}
              transferConfig={this.state.activeRegion}
              handlePaymentMethodChange={this.handlePaymentMethodChange}
            />
          )}

          {this.state.step === 8 && (
            <Review
              name={this.state.name}
              email={this.state.email}
              mobile={this.state.mobile}
              referrer={this.state.referrer}
              deliveryName={this.state.deliveryName}
              deliveryPhone={this.state.deliveryPhone}
              deliveryHome={this.state.deliveryHome}
              deliveryStreet={this.state.deliveryStreet}
              deliverySector={this.state.deliverySector}
              niah={this.state.niah}
              whatParts={this.state.whatParts}
              paymentMethod={this.state.paymentMethod}
              notes={this.state.notes}
              orders={this.state.orders}
              handleReviewEvent={this.handleReviewEvent}
              handleSendOrder={this.handleSendOrder}
              sendError={this.state.sendError}
              forceHomeDelivery={this.state.forceHomeDelivery}
              isTakaful={this.state.isTakaful}
              transferCost={this.state.activeRegion.settings.transfer_cost}
            />
          )}

          {this.state.step <= 8 && this.state.config.online_active && (
            <MobileStepper
              variant="dots"
              steps={9}
              position="static"
              activeStep={this.state.step}
              className="stepper"
              nextButton={
                this.state.reviewMode ? (
                  <Button
                    size="small"
                    onClick={this.handleReviewNext}
                    disabled={this.checkDisableNext()}
                  >
                    تصحيح
                    <KeyboardArrowLeft />
                  </Button>
                ) : (
                  <Button
                    size="small"
                    onClick={this.handleNext}
                    disabled={this.checkDisableNext()}
                  >
                    التالي
                    <KeyboardArrowLeft />
                  </Button>
                )
              }
              backButton={
                <Button
                  size="small"
                  onClick={this.handlePrevious}
                  disabled={this.state.step === 0}
                >
                  <KeyboardArrowRight />
                  السابق
                </Button>
              }
            />
          )}

          {this.state.step === 9 && (
            <div className="center">
              <p>لقد تم ارسال الطلبية.</p>
              <p>الرجاء الانتظار ريثما يتم الحصول على أرقام الأضاحي.</p>
              <CircularProgress />
            </div>
          )}
          {this.state.step === 10 && (
            <div>
              <h3 className="review-header">
                أرقام الأضاحي الخاصة لهذه الطلبية
              </h3>
              <table className="review-orders">
                <tbody>
                  {this.state.orders.map((o, i) => (
                    <tr key={i}>
                      <th className="no">{i + 1}</th>
                      <th className="type">{o.a.label}</th>
                      <td className="top">
                        {this.state.ordersNo[i].map((s, i) => (
                          <span key={i}>
                            {s}
                            <br />
                          </span>
                        ))}
                      </td>
                      <td className="top">{o.partsStr}</td>
                    </tr>
                  ))}
                  <tr>
                    <th />
                    <th className="type">المبلغ المطلوب</th>
                    <td />
                    <td className="rb">
                      <div className="review-total">{totalAmount}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                لقد تم إرسال نسخة من هذه الأرقام على البريد التالي:
                <br />
                {this.state.email}
              </div>
            </div>
          )}
          {this.state.step === 11 && (
            <div dangerouslySetInnerHTML={this.state.ordersNo} />
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;

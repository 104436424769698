import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Niah extends Component {
  state = {
    niah: "أضحية"
  }

  constructor(props) {
    super(props);
    this.state.niah = props.niah.toString();
  }

  handleNiahChange = event => {
    let value = event.target.value;
    this.setState({ niah: value });
    this.props.handleNiahChange(value);
  };

  render() {
    return (
      <div className="component">
        <h3>نيّة الذبح</h3>
        <RadioGroup
          name="niah"
          value={this.state.niah}
          onChange={this.handleNiahChange} >
          {this.props.nawayah.map((nia, key) => {
            return <FormControlLabel key={key} value={nia} label={nia} control={<Radio />} />
          })}
        </RadioGroup>
      </div>
    );
  }
}

export default Niah;

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { Component } from "react";

class Region extends Component {
  state = {
    region: "b",
  };

  constructor(props) {
    super(props);
    this.state.region = props.region;
  }

  handleRegionChange = (event) => {
    let value = event.target.value;
    this.setState({ region: value });
    this.props.handleRegionChange(value);
  };

  render() {
    return (
      <div className="component">
        <h3>المنطقة</h3>
        <RadioGroup
          name="region"
          value={this.state.region}
          onChange={this.handleRegionChange}
        >
          {this.props.regions.map((reg) => {
            return (
              reg.online_active && (
                <FormControlLabel
                  key={reg.id}
                  value={reg.code}
                  label={reg.label}
                  control={<Radio />}
                />
              )
            );
          })}
        </RadioGroup>
        <div className="notes">
          <span className="error">ملاحظة:</span>&nbsp; هذا الطلب لمضحي واحد فقط
          ولنية واحدة لو أردت إدخال أكثر من مضحي أو نوايا مختلفة الرجاء تعبئة
          أكثر من طلب.
        </div>
      </div>
    );
  }
}

export default Region;

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";

class Orders extends Component {
  state = {
    count: 0,
    orders: [],
    totalCount: 0,
    totalAmount: 0,
  };

  constructor(props) {
    super(props);
    this.state.orders = props.orders;
  }

  componentDidMount() {
    this.calcTotal(this.state.orders);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ orders: nextProps.orders });
    this.calcTotal(nextProps.orders);
  }

  calcTotal(orders) {
    var amount = 0;
    for (var i = 0; i < orders.length; i++) {
      // convert string to number
      amount += Number(orders[i].a.price);
    }
    this.setState({
      totalCount: orders.length,
      totalAmount: amount,
    });
  }

  getTypeCount(adahiId) {
    let count = 0;
    this.state.orders.forEach((order) => {
      if (order.a.id === adahiId) count++;
    });
    return count;
  }

  incOrder(adahiId, value) {
    var o = this.state.orders;
    var adahiType = this.props.adahiTypes.find((ele) => {
      return ele.id === adahiId;
    });
    // find where should I start insert
    for (var i = o.length; i >= 0; i--) {
      if (i === 0 || o[i - 1].a.id <= adahiId) {
        // now insert the value required
        for (var j = 0; j < value; j++) {
          o.splice(i, 0, {
            a: adahiType,
            wantParts: 0,
            partsLegs: 0,
            partsBack: 0,
            partsShoulders: 0,
            partsNeck: 0,
            partsGuts: 0,
            partsCow: 0,
            otherCheck: 0,
            partsOther: "",
            partsStr: "",
          });
        }
        break;
      }
    }
    return o;
  }

  decOrder(adahiId, value) {
    var o = this.state.orders;
    for (var i = o.length - 1; i >= 0 && value > 0; i--) {
      if (o[i].a.id === adahiId) {
        o.splice(i, 1);
        value--;
      }
    }
    return o;
  }

  removeOrder(adahiId) {
    var o = this.state.orders;
    for (var i = 0; i < o.length; ) {
      if (o[i].a.id === adahiId) {
        o.splice(i, 1);
      } else {
        i++;
      }
    }
    return o;
  }

  setOrder(adahiId, value) {
    var o;
    var diff = value - this.getTypeCount(adahiId);
    if (diff > 0) {
      o = this.incOrder(adahiId, diff);
    } else if (diff < 0) {
      o = this.decOrder(adahiId, -diff);
    } else o = this.removeOrder(adahiId);
    // this.setState({
    //   orders: o
    // });
    return o;
  }

  handleCheckedChange = (adahiId) => (event) => {
    var v = event.target.checked ? 1 : 0;
    var o = this.setOrder(adahiId, v);
    //console.log(o);
    this.props.handleOrdersChange(o);
  };

  handleOrderChange = (adahiId) => (event) => {
    var o = this.setOrder(adahiId, Number(event.target.value));
    this.props.handleOrdersChange(o);
  };

  render() {
    return (
      <div className="component">
        <h3>الأضاحي المطلوبة</h3>
        <table className="orders">
          <thead>
            <tr>
              <th>الـــنـــــــــــوع</th>
              <th style={{ textAlign: "right" }}>السعر</th>
              <th>الكمية</th>
            </tr>
          </thead>
          <tbody>
            {this.props.adahiTypes.map((typ, index) => {
              return (
                <tr key={index} className="order-row">
                  <td className="order-label">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.getTypeCount(typ.id) !== 0}
                          onChange={this.handleCheckedChange(typ.id)}
                        />
                      }
                      label={typ.label + (typ.category < 3 ? "" : "*")}
                    />
                  </td>
                  <td className="order-price">${typ.price}</td>
                  <td className="order-count">
                    <TextField
                      id="standard-number"
                      type="number"
                      margin="normal"
                      value={this.getTypeCount(typ.id)}
                      onChange={this.handleOrderChange(typ.id)}
                    />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="order-label summary" colSpan="2">
                مجموع عدد الأضحي
              </td>
              <td className="total-count">{this.state.totalCount}</td>
            </tr>
            <tr>
              <td className="order-label summary" colSpan="2">
                مجموع المبلغ
              </td>
              <td className="total-amount">{this.state.totalAmount}</td>
            </tr>
            <tr>
              <td className="order-footnote summary" colSpan="3">
                <sup>*</sup>لا يمكنك الحصول على حصص من هذا النوع.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Orders;

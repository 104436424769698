function calcTotal(orders, paymentMethod, transferCost) {
  if (isNaN(transferCost)) {
    transferCost = 0;
  }
  var amount = 0;
  for (var i = 0; i < orders.length; i++) {
    amount += Number(orders[i].a.price);
    if (paymentMethod === "3") {
      amount += Number(transferCost);
    }
  }
  return amount;
}

//const NO_PARTS_NEEDED = "تـكــــــــافــــــل";
const NO_PARTS_NEEDED = "لا حصص مطلوبة";

// function whatPartsToString(w) {
//   if (w.wantParts === 2) return "كل الحصص";

//   if (w.wantParts === 0) return NO_PARTS_NEEDED;

//   var result = "";
//   if (w.a.category === 1) {
//     //------- Modify the number of breasts.
//     var partsBreasts = 0;
//     if (w.partsNeck === 1) {
//       partsBreasts = 1;
//     }
//     if (w.partsBack === 1) {
//       partsBreasts += 1;
//     }
//     //------- Modify the All parts requested.
//     if (
//       w.partsGuts === 1 &&
//       w.parts_neck === 1 &&
//       w.partsBack === 1 &&
//       w.partsShoulders === 2 &&
//       w.parts_legs === 2
//     ) {
//       result = "كل الحصص";
//     } else {
//       if (w.partsLegs > 0) {
//         result += "فخذ " + w.partsLegs.toString() + " + ";
//       }
//       if (w.partsShoulders > 0) {
//         result += "كتف " + w.partsShoulders.toString() + " + ";
//       }
//       if (partsBreasts > 0) {
//         result += "صدر " + partsBreasts.toString() + " + ";
//       }
//       if (w.partsBack > 0) {
//         result += "ظهر + ";
//       }
//       if (w.parts_neck > 0) {
//         result += "رقبة + ";
//       }
//       if (w.partsGuts > 0) {
//         result += "معلاق + ";
//       }
//       if (result.length > 3) {
//         result = result.slice(0, -3);
//       }
//     }
//   } else if (w.partsCow > 0) {
//     if (w.a.category === 2 && w.partsCow >= 9) {
//       result = "كل الحصص";
//     } else {
//       result = w.partsCow.toString() + " حصص من عجل";
//     }
//   }
//   if (w.partsOther !== "") {
//     result += " + " + w.partsOther;
//   }
//   return result;
// }

function whatPartsToString(w, partsMethod) {
  if (w.wantParts === 2) return "كل الحصص";
  if (w.wantParts === 0) return NO_PARTS_NEEDED;
  let result = "";

  if (w.partsCow > 0) {
    if (w.partsCow >= 9) {
      result = "كل الحصص";
    } else {
      result = w.partsCow.toString() + " حصص من عجل";
    }
  } else {
    if (partsMethod === 0) {
      let partsBreasts = 0;
      if (w.partsNeck === 1) {
        partsBreasts = 1;
      }
      if (w.partsBack === 1) {
        partsBreasts += 1;
      }
      if (
        w.partsGuts === 1 &&
        w.partsNeck === 1 &&
        w.partsBack === 1 &&
        w.partsShoulders === 2 &&
        w.partsLegs === 2
      ) {
        result = "كل الحصص";
      } else {
        if (w.partsLegs > 0) {
          result += "فخذ " + w.partsLegs.toString() + " + ";
        }
        if (w.partsShoulders > 0) {
          result += "كتف " + w.partsShoulders.toString() + " + ";
        }
        if (partsBreasts > 0) {
          result += "صدر " + partsBreasts.toString() + " + ";
        }
        if (w.partsBack > 0) {
          result += "ظهر + ";
        }
        if (w.partsNeck > 0) {
          result += "رقبة + ";
        }
        if (w.partsGuts > 0) {
          result += "معلاق + ";
        }
        if (result.length > 3) {
          result = result.slice(0, -3);
        }
      }
    } else if (partsMethod === 1) {
      if (
        w.partsGuts === 1 &&
        w.partsNeck === 1 &&
        w.partsBack === 2 &&
        w.partsShoulders === 2 &&
        w.partsLegs === 2
      )
        result = "كل الحصص";
      else {
        result = "";
        if (w.partsLegs > 0) result += "فخذ " + w.partsLegs.toString() + " + ";
        if (w.partsShoulders > 0)
          result += "كتف " + w.partsShoulders.toString() + " + ";
        if (w.partsBack > 0) result += "صدر " + w.partsBack.toString() + " + ";
        if (w.partsNeck > 0) result += "رقبة" + " + ";
        if (w.partsGuts > 0) result += "معلاق" + " + ";
        if (result.length > 3) result = result.slice(0, -3);
      }
    }
  }
  if (w.partsOther !== "") {
    result += " + " + w.partsOther;
  }
  return result;
}

exports.calcTotal = calcTotal;
exports.whatPartsToString = whatPartsToString;
